<template>
  <div
    class="mm-product-img"
    :class="{ 'mm-product-img-first': isFirst, 'mm-product-img-last': isLast}"
    @mousemove="onMouseMoveImg($event)"
    @mouseleave="onMouseleave()"
  >
    <template v-if="!pictures?.length">
      <BaseImg
        src="/images/empty-product-images.png"
        alt="product"
        class="mm-product-img"
        format="webp"
        loading="lazy"
        @click="$emit('imageClick', -1)"
      />
    </template>

    <template v-else>
      <Carousel
        v-if="!isHideSlider && !disabledCarousel"
        ref="myCarousel"
        :mouse-drag="false"
        :wrap-around="true"
      >
        <Slide
          v-for="(picture, index) in (pictures.slice(0, !isMounted ? 1 : pictures.length))"
          :key="index"
        >
          <BaseImg
            :src="picture"
            alt="product"
            loading="lazy"
            format="webp"
            @click="$emit('imageClick', index)"
          />
        </Slide>

        <template #addons>
          <Pagination />
        </template>
      </Carousel>

      <BaseImg
        v-else
        :src="pictures?.[0]"
        alt="product"
        class="mm-product-img"
        format="webp"
        loading="lazy"
        @click="$emit('imageClick', 0)"
      />

      <template v-if="isArrowMode">
        <SvgIcon
          class="carousel-arrow carousel-arrow-left"
          :src="EIconPath.NavigationArrowLeft20PxSvg"
          @click="onPrev"
        />
        <SvgIcon
          class="carousel-arrow carousel-arrow-right"
          :src="EIconPath.NavigationArrowRight20PxSvg"
          @click="onNext"
        />
      </template>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { Carousel, Slide, Pagination } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import { DocumentBreakpointsUtils } from 'shared/utils/documentBreakpoints.util';
import BaseImg from 'shared/components/BaseImg';
import { EIconPath } from '../enums/iconPath.enum';

const props = withDefaults(defineProps<{
  pictures: Array<string>;
  hideMobileSlider?: boolean;
  isArrowMode?: boolean;
  disabledCarousel?: boolean;
}>(),
{
  hideMobileSlider: true,
  isArrowMode: false,
});

defineEmits<{
  (e: 'imageClick', index: number): void; /** integer or -1, if image is empty **/
}>();

const isMounted = ref(false);
const myCarousel = ref<InstanceType<typeof Carousel>>(null);
const timerMouseLeave = ref<ReturnType<typeof setTimeout> | null>(null);
const isHideSlider = computed(() => props.hideMobileSlider && DocumentBreakpointsUtils.getIsRenderedMobile());

const isFirst = computed(() => myCarousel.value?.data?.currentSlide?.value === 0);
const isLast = computed(() => myCarousel.value?.data?.currentSlide?.value === (props.pictures || []).length - 1);

function onPrev() {
  !isFirst.value && myCarousel.value?.prev();
}

function onNext() {
  !isLast.value && myCarousel.value?.next();
}

function onMouseMoveImg($event: MouseEvent): void {
  if (isHideSlider.value || props.isArrowMode) {
    return;
  }

  const currentWidth = ($event?.target as HTMLElement)?.offsetWidth || 1;
  const xLeft = Math.abs($event.offsetX);
  const picturesLength = props.pictures?.length || 1;
  const nextSlide = Math.trunc(xLeft / (currentWidth / picturesLength));

  myCarousel.value?.slideTo(
    nextSlide > picturesLength
      ? picturesLength
      : nextSlide < 0
        ? 0
        : nextSlide,
  );
}

function onMouseleave(): void {
  if (timerMouseLeave.value) {
    clearTimeout(timerMouseLeave.value);
    timerMouseLeave.value = null;
  }

  timerMouseLeave.value = setTimeout(() => myCarousel.value?.slideTo(0), 300);
}

onMounted(() => {
  isMounted.value = true;
  myCarousel.value?.restartCarousel();
});

defineExpose({
  carousel: myCarousel,
});
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-product-img {
  margin: 0 auto;
  margin-top: 10px;
  text-align: center;
  max-width: 272px;
  max-height: 200px;
  width: 100%;
  height: 100%;
  position: relative;

  img {
    height: 200px;
    width: 272px;
    object-fit: contain;
  }

  :deep(.carousel__pagination) {
    padding: 0;
    margin-bottom: 0;
    width: 100%;
    list-style: none !important;
    list-style-type: none !important;

    .carousel__pagination-item {
      &:only-child {
        display: none;
      }

      .carousel__pagination-button {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $dark-gray;


        &--active {
          background: linear-gradient(107.81deg, #50D04D 0%, #00A77B 95.12%);
        }
      }
    }
  }

  :deep(.carousel__track) {
    list-style: none !important;
    list-style-type: none !important;
  }

  .carousel-arrow {
    display: block;
    width: 24px;
    height: 24px;
    background-color: $light-gray;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 4px;
    cursor: pointer;

    &-left {
      left: 0;

      &:hover {
        :deep(path) {
          fill: $link;
        }
      }
    }

    &-right {
      right: 0;

      &:hover {
        :deep(path) {
          fill: $link;
        }
      }
    }
  }

  &-first {
  .carousel-arrow-left {
    :deep(path) {
      fill: $text-disabled;
      cursor: default;
      pointer-events: none;
      }

      &:hover {
        :deep(path) {
          fill: $text-disabled;
        }
      }
    }
  }

  &-last {
    .carousel-arrow-right {
      :deep(path) {
        fill: $text-disabled;
        cursor: default;
        pointer-events: none;
      }

      &:hover {
        :deep(path) {
          fill: $text-disabled;
        }
      }
    }
  }
}

:deep(.carousel) {
  width: 100%;
  height: 100%;

  .carousel__viewport, .carousel__track {
    width: 100%;
  }
}

@media only screen and (max-width: 1279px) {
  .mm-product-img {
    margin: 0 auto;
    width: 146.05px;
    height: 148px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 767px) {
  .mm-product-img {
    margin: 0 auto;
    width: 136px;
    height: 136px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 360px) {
  .mm-product-img {
    width: 140px;
    height: 140px;

    img {
      width: 140px;
      height: 140px;
    }
  }
}
</style>
