<template>
  <div
    class="mm-compare-btn"
    :class="{
      'mm-compare-btn--rounded': rounded,
      'mm-compare-btn--text': text,
      'mm-compare-btn--active': mounted && active,
      'mm-compare-btn--disabled': !active && disabled
    }"
    @click.stop.prevent="onClickBtn"
  >
    <BaseTooltip
      hover
      arrow
      :disabled="disabledTooltip"
      class="mm-tooltip mm-tooltip--base mm-compare-btn__tooltip"
      :show="tooltipIsShown"
      :offset-distance="offsetDistance"
      @open:popper="tooltipIsShown = $event"
      @close:popper="tooltipIsShown = $event"
    >
      <div class="mm-compare-btn__wrapper">
        <SvgIcon
          class="mm-compare-btn__icon"
          :src="EIconPath.ActionCompareSvg"
        />
      </div>

      <template #content>
        {{ contentText }}
      </template>
    </BaseTooltip>
    <span
      v-if="text"
      class="mm-compare-btn__text"
    >
      {{ contentText }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import { EIconPath } from '../enums/iconPath.enum';
import BaseTooltip from './BaseTooltip.vue';

const props = defineProps<{
  active?: boolean;
  disabled?: boolean;
  rounded?: boolean;
  text?: boolean;
}>();

const emit = defineEmits<{
  (e: 'add'): void;
  (e: 'open'): void;
}>();

const tooltipIsShown = ref<undefined | boolean>(undefined);
const mounted = ref(false);

const contentText = computed(
  () => props.active
    ? 'Перейти к сравнению'
    : 'Сравнить',
);

const offsetDistance = computed(
  () => props.rounded
    ? 10
    : 5,
);

const disabledTooltip = computed(
  () => props.text || props.disabled,
);

async function onClickBtn(): Promise<void> {
  if (props.disabled) {
    return;
  }

  if (props.active) {
    return emit('open');
  }

  tooltipIsShown.value = undefined;
  emit('add');
}

onMounted(() => {
  mounted.value = true;
});
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-compare-btn {
  :deep(.popper) {
    width: max-content;
    max-width: max-content;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &__icon {
    :deep(path) {
      transition: all 0.15s;
      fill: $text-disabled;
    }
  }

  &--active,
  &:hover {
    .mm-compare-btn__icon {
      :deep(path) {
        fill: $link;
      }
    }
  }


  &--rounded {
    .mm-compare-btn {
      &__wrapper {
        background-color: $light-gray;
        border-radius: 50%;
        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__icon {
        width: 20px;
        height: 20px;

        :deep(path) {
          fill: $text-dark-green;
        }
      }
    }

    &.mm-compare-btn--active,
    &:hover {
      .mm-compare-btn__icon {
        :deep(path) {
          fill: $link;
        }
      }
    }
  }

  &--text {
    display: flex;
    align-items: flex-end;
    cursor: pointer;

    .mm-compare-btn {
      &__icon {
        margin-right: 12px;
        width: 24px;
        height: 24px;

        :deep(path) {
          fill: $text-dark-green;
        }
      }

      &__text {
        transition: all 0.15s;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: $text-dark-green;
        font-weight: 500;
      }
    }

    &.mm-compare-btn--active,
    &:hover {
      .mm-compare-btn {
        &__icon {
          :deep(path) {
            fill: $link;
          }
        }

        &__text {
          color: $link;
        }
      }
    }
  }

  &--disabled {
    cursor: not-allowed;

    .mm-compare-btn {
      &__wrapper,
      &__icon {
        cursor: not-allowed;
      }

      &__icon {
        :deep(path) {
          fill: $text-disabled !important;
        }
      }

      &__text {
        color: $text-disabled !important;
      }
    }
  }
}
</style>
